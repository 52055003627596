export function getColorByScale(
    value: number,
    valueOptions: { min: number, max: number },
    colorOptions: { min: string, max: string }
  ): string {
    return interpolateColor(value / (valueOptions.max || 1), colorOptions.min, colorOptions.max);
  }

function interpolateColor(pctValue: number, minColor: string, maxColor: string): string {
  const clampedPctValue = Math.min(1, Math.max(0, pctValue));

  if (clampedPctValue >= 1) {
    return maxColor;
  }

  const minColorRGB = hexToRgb(minColor);
  const maxColorRGB = hexToRgb(maxColor);

  if (!minColorRGB || !maxColorRGB) {
    throw new Error('Invalid color format. Please provide valid hexadecimal colors.');
  }

  const r = Math.round(minColorRGB.r + (maxColorRGB.r - minColorRGB.r) * clampedPctValue);
  const g = Math.round(minColorRGB.g + (maxColorRGB.g - minColorRGB.g) * clampedPctValue);
  const b = Math.round(minColorRGB.b + (maxColorRGB.b - minColorRGB.b) * clampedPctValue);

  return rgbToHex(r, g, b);
}

function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  hex = hex.replace(/^#/, '');

  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }

  const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function rgbToHex(r: number, g: number, b: number): string {
  // tslint:disable-next-line:no-bitwise
  return `#${ ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase() }`;
}
