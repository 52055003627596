export enum Permission {
  GLOBAL_ADMIN = 'global_admin',

  ARTICLES_LOAD = 'articles_load',
  ARTICLES_CREATE = 'articles_create',
  ARTICLES_UPDATE = 'articles_update',
  ARTICLES_DELETE = 'articles_delete',

  CONSTRUCTION_PROJECTS_LOAD = 'constructionProjects_load',
  CONSTRUCTION_PROJECTS_CREATE = 'constructionProjects_create',
  CONSTRUCTION_PROJECTS_UPDATE = 'constructionProjects_update',
  CONSTRUCTION_PROJECTS_DELETE = 'constructionProjects_delete',

  CONTACT_PERSONS_LOAD = 'contactPersons_load',
  CONTACT_PERSONS_CREATE = 'contactPersons_create',
  CONTACT_PERSONS_UPDATE = 'contactPersons_update',
  CONTACT_PERSONS_DELETE = 'contactPersons_delete',

  CUSTOMERS_LOAD = 'customers_load',
  CUSTOMERS_CREATE = 'customers_create',
  CUSTOMERS_UPDATE = 'customers_update',
  CUSTOMERS_DELETE = 'customers_delete',

  DELIVERY_CONDITIONS_LOAD = 'deliveryConditions_load',
  DELIVERY_CONDITIONS_CREATE = 'deliveryConditions_create',
  DELIVERY_CONDITIONS_UPDATE = 'deliveryConditions_update',
  DELIVERY_CONDITIONS_DELETE = 'deliveryConditions_delete',

  OFFERS_LOAD = 'offers_load',
  OFFERS_CREATE = 'offers_create',
  OFFERS_UPDATE = 'offers_update',
  OFFERS_TRANSFER = 'offers_transfer',

  ORDERS_LOAD = 'orders_load',
  ORDERS_UPDATE = 'orders_update',
  ORDERS_TRANSFER_INVOICE = 'orders_transferInvoice',
  ORDERS_TRANSFER_DELIVERY_NOTE = 'orders_transferDeliveryNote',

  DELIVERY_NOTES_LOAD = 'deliveryNotes_load',
  DELIVERY_NOTES_UPDATE = 'deliveryNotes_update',
  DELIVERY_NOTES_DELETE = 'deliveryNotes_delete',
  DELIVERY_NOTE_REPLIES_LOAD = 'deliveryNoteReplies_load',
  DELIVERY_NOTE_REPLIES_CREATE = 'deliveryNoteReplies_create',
  DELIVERY_NOTE_REPLIES_UPDATE = 'deliveryNoteReplies_update',
  DELIVERY_NOTE_REPLIES_DELETE = 'deliveryNoteReplies_delete',
  INVOICES_LOAD = 'invoices_load',
  INVOICES_UPDATE = 'invoices_update',
  INVOICES_TRANSFER = 'invoices_transfer',
  INVOICE_PAYMENTS_CREATE = 'invoicePayments_create',
  INVOICE_PAYMENTS_DELETE = 'invoicePayments_delete',

  CREDIT_NOTES_LOAD = 'creditNotes_load',
  CREDIT_NOTES_UPDATE = 'creditNotes_update',

  PAYMENT_CONDITIONS_LOAD = 'paymentConditions_load',
  PAYMENT_CONDITIONS_CREATE = 'paymentConditions_create',
  PAYMENT_CONDITIONS_UPDATE = 'paymentConditions_update',
  PAYMENT_CONDITIONS_DELETE = 'paymentConditions_delete',

  USER_LOAD = 'user_load',
  USER_CREATE = 'user_create',
  USER_UPDATE = 'user_update',
  USER_DELETE = 'user_delete',

  VEHICLE_LOAD = 'vehicles_load',
  VEHICLE_CREATE = 'vehicles_create',
  VEHICLE_UPDATE = 'vehicles_update',
  VEHICLE_DELETE = 'vehicles_delete',

  VEHICLE_TYPE_LOAD = 'vehicleTypes_load',
  VEHICLE_TYPE_CREATE = 'vehicleTypes_create',
  VEHICLE_TYPE_UPDATE = 'vehicleTypes_update',
  VEHICLE_TYPE_DELETE = 'vehicleTypes_delete',

  DISPOSITION_LOAD = 'disposition_load',
  DISPOSITION_UPDATE = 'disposition_update',

  DELIVERY_NOTE_ASSIGNMENT_LOAD = 'deliveryNoteAssignments_load',
  DELIVERY_NOTE_ASSIGNMENT_CREATE = 'deliveryNoteAssignments_create',
  DELIVERY_NOTE_ASSIGNMENT_UPDATE = 'deliveryNoteAssignments_update',
  DELIVERY_NOTE_ASSIGNMENT_DELETE = 'deliveryNoteAssignments_delete',

  APPLICATION_SETTINGS_UPDATE = 'applicationSettings_update',
  APPLICATION_SETTINGS_LOAD = 'applicationSettings_load',
  RECEIPT_RENT_DURATION_UPDATE = 'receiptRentDurationDays_update',
  RECEIPT_NUMBER_OF_TRANSPORTS_UPDATE = 'receiptNumberOfTransports_update',

  RAP_LOAD = 'rap_load',
  RAP_CREATE = 'rap_create',
  RAP_UPDATE = 'rap_update',
  RAP_DELETE = 'rap_delete',

  EXPORTS_BOOKING_RECORDS_READ = 'bookingRecords_read',
  EXPORTS_CUSTOMER_ACCOUNTS_READ = 'customerAccounts_read',
  EXPORTS_TOTAL_GROSS_BALANCE_READ = 'totalGrossBalance_read',
  EXPORTS_TOTAL_NET_BALANCE_READ = 'totalNetBalance_read',

  EXPORTS_OFFER_RECEIPT_LIST_READ = 'exportOfferReceiptList_read',
  EXPORTS_ORDER_RECEIPT_LIST_READ = 'exportOrderReceiptList_read',
  EXPORTS_DELIVERY_NOTE_RECEIPT_LIST_READ = 'exportDeliveryNoteReceiptList_read',
  EXPORTS_INVOICE_RECEIPT_LIST_READ = 'exportInvoiceReceiptList_read',
  EXPORTS_CREDIT_NOTE_RECEIPT_LIST_READ = 'exportCreditNoteReceiptList_read',
  EXPORTS_INVOICE_REVENUE_READ = 'exportInvoiceRevenue_read',
  EXPORTS_ABROAD_ARTICLES_READ = 'exportAbroadArticles_read',

  EXPORT_RAP_READ = 'exportRaps_read',

  PLATE_COUNT_STATISTICS_LOAD = 'plateCountStatistics_load',
}

export const ALL_EXPORT_PERMISSIONS: string[] = Object.keys(Permission).filter(key => key.startsWith('EXPORT'))
.map((key: string, _) => {
// @ts-ignore
return Permission[key] as string;
});
